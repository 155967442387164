import React from "react";
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    Modal,
    Spinner
} from "reactstrap";

const baseUrl = window.location.origin;
let apiUrl = '';

if (baseUrl.includes('127.0.0.1') || baseUrl.includes('localhost')) {
    apiUrl = 'http://127.0.0.1:8000';
    } else {
        apiUrl = baseUrl;
    }

function SectionContact() {
    const [disabled, setDisabled] = React.useState(true);
    const [inputName, setInputName] = React.useState("");
    const [inputEmail, setInputEmail] = React.useState("");
    const [validEmail, setValidEmail] = React.useState(false);
    const [inputMessage, setInputMessage] = React.useState("");
    const [messageSent, setMessageSent] = React.useState(false);
    const [messageIsSending, setMessageIsSending] = React.useState(false);
    const [messageButton, setMessageButton] = React.useState("Send Message");

    function validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            setInputEmail(email);
            setValidEmail(true);
        } else {
            setInputEmail("");
            setValidEmail(false);
        }
    }

    React.useEffect(() => {
        if (!inputName || !inputEmail || !inputMessage ) {
            setDisabled(true);
            setMessageButton("Send Message");
        } else {
            setDisabled(false);
        }
    }, [inputName, inputEmail, inputMessage]);

    function sendMessage() {
        setMessageIsSending(true);
        axios.post(`${apiUrl}/api/send/`, {
            "turnstile_token": document.forms['contact_form']['cf-turnstile-response'],
            "name": document.forms['contact_form']['f_name'].value,
            "email": document.forms['contact_form']['f_email'].value,
            "message": document.forms['contact_form']['f_message'].value
        })
            .then(function (response) {
                if (response.data.success) {
                    setMessageButton("Sent");
                    setMessageIsSending(false);
                    setMessageSent(true);
                } else {
                    alert('Server Error: Message was not sent.');
                    setMessageIsSending(false);
                }
            }
        );
    }

    return (
        <div className="contactus-1 section-image" style={{backgroundImage: "url(" + require("../assets/img/section-contact.jpg") + ")"}}>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="10">
                        <div className="card-contact no-transition card">
                            <h2 className="text-center card-title">
                                Contact Us
                            </h2>
                            <Row>
                                <Col className="ml-auto" md="5">
                                    <div className="card-body">
                                        <div className="info info-horizontal">
                                            <div className="description">
                                                <h4 className="info-title">
                                                    Need more information?
                                                </h4>
                                                <p>
                                                    You can contact us by filling this form, or you can call/email us using the info below.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="info info-horizontal">
                                            <div className="icon icon-danger">
                                                <i className="nc-icon nc-mobile"/>
                                            </div>
                                            <div className="description">
                                                <h4 className="info-title">
                                                    Give us a ring
                                                </h4>
                                                <p>
                                                    Mike Carmona<br/>
                                                    +1 (323) 459-7207<br/>
                                                    <a href="mailto:production@carmonasound.com">production@carmonasound.com</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="mr-auto" md="5">
                                    <Form id="contact-form" name="contact_form">
                                        <div className="card-body">
                                            <div className="label-floating form-group">
                                                <label className="control-label">
                                                    Name
                                                </label>
                                                <InputGroup className={inputName ? "has-success" : ""}>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-single-02"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Name" name="f_name" type="text" onInput={e => setInputName(e.target.value)}/>
                                                </InputGroup>
                                            </div>
                                            <div className="label-floating form-group">
                                                <label className="control-label">
                                                    Email
                                                </label>
                                                <InputGroup className={validEmail ? "has-success" : ""}>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-email-85"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Email" name="f_email" type="email" onInput={e => validateEmail(e.target.value)}/>
                                                </InputGroup>
                                            </div>
                                            <div className="label-floating form-group">
                                                <label className="control-label">
                                                    Message
                                                </label>
                                                <InputGroup className={inputMessage ? "has-success" : ""}>
                                                    <Input placeholder="Your message..." name="f_message" type="textarea" rows="4" onInput={e => setInputMessage(e.target.value)}/>
                                                </InputGroup>
                                            </div>
                                            <Row className="justify-content-end">
                                                <Col md="12">
                                                    <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}></div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12" className="pt-3">
                                                    <button type="button" className="btn btn-fill btn-primary" disabled={disabled} onClick={sendMessage}>
                                                        {messageIsSending ? <>{messageButton} <Spinner size="sm" color="neutral" className="ml-2 mt-1"/></> : <>{messageButton}</>}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={messageSent} className="modal-sm" toggle={() => setMessageSent(false)}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <b>MESSAGE SENT  <i className="nc-icon nc-send"/></b>
                    </h5>
                </div>
                <div className="modal-body text-center">
                    We will get in touch with you asap!
                </div>
                <div className="modal-footer">
                    <Button className="btn-link success" color="info" data-dismiss="modal" type="button" onClick={() => setMessageSent(false)}>
                        OKAY
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default SectionContact;

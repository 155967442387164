import React from "react";
import axios from "axios";
// reactstrap components
import {
    Row,
    Spinner,
    Fade
} from "reactstrap";

const baseUrl = window.location.origin;
let apiUrl = '';

if (baseUrl.includes('127.0.0.1') || baseUrl.includes('localhost')) {
    apiUrl = 'http://127.0.0.1:8000';
    } else {
        apiUrl = baseUrl;
    }

function getId(url) {
    let videoId = [];
    const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const regExpVimeo = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const youtubeMatch = url.match(regExpYoutube);
    const vimeoMatch = url.match(regExpVimeo);

    if (youtubeMatch) {
        videoId = ['youtube', youtubeMatch[2]];
    }

    if (vimeoMatch) {
        videoId = ['vimeo', vimeoMatch[5]];
    }
    return videoId;
}

function SectionWorkVideoList({category}) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [items, setItems] = React.useState([]);
    const [numberPages, setNumberPages] = React.useState(1);


    // function delay() {
    //     return new Promise(function(resolve, reject) {
    //         setTimeout(function () {
    //             resolve(42);
    //         }, 1500);
    //     });
    // }

    async function getVideos(page) {
        const tempArray = [];
        try {
            const res = await axios.get(`${apiUrl}/api/work/?category=${category}&page=${page}`);
            for (let i = 0; i < res.data.results.length; i++) {
                const videoId = getId(res.data.results[i].link);

                if (videoId[0] === 'youtube') {
                    const embedLink = `https://www.youtube-nocookie.com/embed/${videoId[1]}?iv_load_policy=3&modestbranding=1`;
                    tempArray.push(
                        <div key={res.data.results[i].id} className="col-md-4 pt-5">
                            <div className="iframe-container">
                                <Fade in={true} tag="iframe" title={res.data.results[i].title} src={embedLink} height="250" frameBorder="0"/>
                                <br/>
                                <span style={{color: '#FFF' }}>{res.data.results[i].title}</span>
                            </div>
                        </div>
                    );
                }

                if (videoId[0] === 'vimeo') {
                    const embedLink = `https://player.vimeo.com/video/${videoId[1]}?byline=0&portrait=0&dnt=1&title=0&transparent=0`;
                    tempArray.push(
                        <div key={res.data.results[i].id} className="col-md-4 pt-5">
                            <div className="iframe-container">
                                <Fade in={true} tag="iframe" title={res.data.results[i].title} src={embedLink} height="250" frameBorder="0"/>
                                <br/>
                                <span style={{color: '#FFF' }}>{res.data.results[i].title}</span>
                            </div>
                        </div>
                    );
                }
            }
            return tempArray;
        } catch (e) {
            return null;
        }
    }

    //On Category change
    React.useEffect(() => {
        async function getPageCount() {
            let pageCount = 1;
            try {
                const res = await axios.get(`${apiUrl}/api/work/?category=${category}`);
                if (res.data.count > 0) {
                    pageCount = Math.ceil(res.data.count / 6);
                }
                return pageCount;
            } catch (e) {
                return 1;
            }
        }

        //Reset video array
        setItems([]);

        //Reset current page to 1
        setCurrentPage(1);

        //Populate video thumbnails
        (async function() {
            //await delay();
            let pageCount = await getPageCount();
            setNumberPages(pageCount);
            let videos = await getVideos(1);
            setItems(videos);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    //When current page changes, aka when 'Load More' is clicked
    React.useEffect(() => {
        if (currentPage !== 1) {
            (async function() {
                let videos = await getVideos(currentPage);
                for (let i = 0; i < videos.length; i++) {
                    setItems( items => [
                        ...items,
                        videos[i]
                    ]);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const loadMore = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Row>
                {items.length === 0
                    ? <div className="ml-auto mr-auto text-center col-md-8 pt-5">
                        <Spinner size="sm" color="primary" className="mr-2 mt-1"/> Loading Work Samples...
                    </div>
                    : <>{items}</>
                }
            </Row>
            {(currentPage !== numberPages && items.length !== 0) &&
            <Row>
                <div className="ml-auto mr-auto text-center col-md-8 pt-5">
                    <a href="#!" onClick={() => loadMore(currentPage + 1)}>Load More...</a>
                </div>
            </Row>
            }
        </>
    );
}

export default SectionWorkVideoList;
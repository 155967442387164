import React from "react";
import axios from "axios";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Modal,
    Button,
    Table,
    Spinner,
    Fade
} from "reactstrap";

const baseUrl = window.location.origin;
let apiUrl = '';

if (baseUrl.includes('127.0.0.1') || baseUrl.includes('localhost')) {
    apiUrl = 'http://127.0.0.1:8000';
    } else {
        apiUrl = baseUrl;
    }

function NavigationBar() {
    const [rateState, loadRate] = React.useState(false);
    const [termsState, loadTerms] = React.useState(false);
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const [rateModal, showRateModal] = React.useState(false);
    const [termsModal, showTermsModal] = React.useState(false);
    const [rates, setRates] = React.useState([]);
    const [terms, setTerms] = React.useState([]);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    // function delay() {
    //     return new Promise(function(resolve, reject) {
    //         setTimeout(function () {
    //             resolve(42);
    //         }, 1500);
    //     });
    // }

    //RATES
    React.useEffect(() => {
        function formatTypeNames(string) {
            let noUnderscore = string.replace('_', ' ');
            noUnderscore = noUnderscore.substr(1);
            noUnderscore = noUnderscore.replace('_', ' ');
            noUnderscore = noUnderscore.replace('_', ' ');
            return noUnderscore.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }

        async function getRateTypes() {
            const tempArray = [];
            try {
                //await delay();
                const res = await axios.get(`${apiUrl}/api/rate/?fields=type`);
                for (let i = 0; i < res.data.length; i++) {
                    if (!tempArray.includes(res.data[i].type)) {
                        tempArray.push(res.data[i].type);
                    }
                }
                return tempArray;
            } catch (e) {
                return null;
            }
        }

        async function getRatesFromType(rateType) {
            const tempArray = [];
            try {
                const res = await axios.get(`${apiUrl}/api/rate/?type=${rateType}`);
                for (let i = 0; i < res.data.length; i++) {
                    tempArray.push(
                        <React.Fragment key={res.data[i].id}>
                            <tr>
                                <td>
                                    {res.data[i].name}
                                </td>
                                <td className="text-right">
                                    {res.data[i].price}
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                }
                return tempArray;
            } catch (e) {
                return null;
            }
        }

        if (rateState) {
            (async function(){
                let rateTypes = await getRateTypes();
                for (let i = 0; i < rateTypes.length; i++) {
                    let results = await getRatesFromType(rateTypes[i]);
                    setRates(rates => [
                        ...rates,
                        <Fade in={true} tag="div" key={i}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th><b>{formatTypeNames(rateTypes[i])}</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results}
                                </tbody>
                            </Table>
                        </Fade>
                    ]);
                }
            })();
        }
    }, [rateState]);

    //TERMS
    React.useEffect(() => {
        if (termsState) {
            (async function(){
                //await delay();
                const res = await axios.get(`${apiUrl}/api/terms/`);
                for (let i = 0; i < res.data.length; i++) {
                    setTerms(terms => [
                        ...terms,
                        <Fade in={true} tag="li" key={res.data[i].id}>
                            {res.data[i].term}
                        </Fade>
                    ])
                }
            })();
        }
    }, [termsState]);

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("navbar-transparent");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <Navbar className={classnames("fixed-top bg-dark", navbarColor)} color-on-scroll="300" expand="lg">
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand data-placement="bottom" style={{color: 'white'}}>
                        Carmona Sound
                    </NavbarBrand>
                    <button aria-expanded={navbarCollapse} className={classnames("navbar-toggler navbar-toggler", {toggled: navbarCollapse})} onClick={toggleNavbarCollapse}>
                        <span className="navbar-toggler-bar bar1"/>
                        <span className="navbar-toggler-bar bar2"/>
                        <span className="navbar-toggler-bar bar3"/>
                    </button>
                </div>
                <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
                    <Nav navbar>
                        <NavItem>
                            <NavLink href={require("../assets/resume.pdf")} target="_blank">
                                <i className="fa fa-file-text"/> Resume
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" onClick={() => {showRateModal(true);loadRate(true);}}>
                                <i className="fa fa-calculator"/> Rates
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" onClick={() => {showTermsModal(true);loadTerms(true);}}>
                                <i className="fa fa-pencil"/> T&C
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
            <Modal isOpen={rateModal} className="modal-lg" toggle={() => showRateModal(false)}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <b>Rate Card</b>
                    </h5>
                    <button aria-label="Close" className="close pt-0" data-dismiss="modal" type="button" onClick={() => showRateModal(false)}>
                        <span aria-hidden={"true"}>x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {rates.length === 0
                        ? <><Spinner size="sm" color="primary"/> Loading Rate Types...</>
                        : <>{rates}
                        <div className="text-center">
                            <a href={require("../assets/carmonasound_rates.jpg")} download><i className="fa fa-download"/> <b>Download the Rate Sheet</b></a>
                        </div>
                    </>
                    }
                </div>
                <div className="modal-footer">
                    <Button className="btn-link" color="default" data-dismiss="modal" type="button" onClick={() => showRateModal(false)}>
                        Close
                    </Button>
                </div>
            </Modal>
            <Modal isOpen={termsModal} className="modal-lg" toggle={() => showTermsModal(false)}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <b>Terms & Conditions</b>
                    </h5>
                    <button aria-label="Close" className="close pt-0" data-dismiss="modal" type="button" onClick={() => showTermsModal(false)}>
                        <span aria-hidden={"true"}>x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {terms.length === 0
                        ? <><Spinner size="sm" color="primary"/> Loading Terms & Conditions...</>
                        : <ul>
                            {terms}
                        </ul>
                    }
                </div>
                <div className="modal-footer">
                    <Button className="btn-link" color="default" data-dismiss="modal" type="button" onClick={() => showTermsModal(false)}>
                        Close
                    </Button>
                </div>
            </Modal>
        </Navbar>
    );
}

export default NavigationBar;

import React from "react";
import axios from "axios";
import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Col,
    Container,
    Row
} from "reactstrap";

const baseUrl = window.location.origin;
let apiUrl = '';

if (baseUrl.includes('127.0.0.1') || baseUrl.includes('localhost')) {
    apiUrl = 'http://127.0.0.1:8000';
    } else {
        apiUrl = baseUrl;
    }

function SectionAboutUs() {
    const [mikeButtons, setMikeButtons] = React.useState([]);
    const [jenButtons, setJenButtons] = React.useState([]);

    React.useEffect(() => {
        async function getSocialMediaButtons(person) {
            const tempArray = [];
            try {
                const res = await axios.get(`${apiUrl}/api/social/?person=${person}`);
                for (let i = 0; i < res.data.length; i++) {
                    switch (res.data[i].button) {
                        case "facebook":
                            tempArray.push(
                                <React.Fragment key={res.data[i].id}>
                                    <a className="btn-just-icon btn btn-facebook-bg mr-1" href={res.data[i].link}>
                                        <i className="fa fa-facebook"/>
                                    </a>
                                </React.Fragment>
                            );
                            break;
                        case "instagram":
                            tempArray.push(
                                <React.Fragment key={res.data[i].id}>
                                    <a className="btn-just-icon btn btn-instagram-bg mr-1" href={res.data[i].link}>
                                        <i className="fa fa-instagram"/>
                                    </a>
                                </React.Fragment>
                            );
                            break;
                        case "twitter":
                            tempArray.push(
                                <React.Fragment key={res.data[i].id}>
                                    <a className="btn-just-icon btn btn-twitter-bg mr-1" href={res.data[i].link}>
                                        <i className="fa fa-twitter"/>
                                    </a>
                                </React.Fragment>
                            );
                            break;
                        case "linkedin":
                            tempArray.push(
                                <React.Fragment key={res.data[i].id}>
                                    <a className="btn-just-icon btn btn-linkedin-bg mr-1" href={res.data[i].link}>
                                        <i className="fa fa-linkedin"/>
                                    </a>
                                </React.Fragment>
                            );
                            break;
                        case "youtube":
                            tempArray.push(
                                <React.Fragment key={res.data[i].id}>
                                    <a className="btn-just-icon btn btn-youtube-bg mr-1" href={res.data[i].link}>
                                        <i className="fa fa-youtube"/>
                                    </a>
                                </React.Fragment>
                            );
                            break;
                        default:
                            break;
                    }
                }
                return tempArray;
            } catch (e) {
                return null;
            }
        }

        (async function() {
            let buttons = await getSocialMediaButtons("mike");
            for (let i = 0; i < buttons.length; i++) {
                setMikeButtons(mikeButtons => [
                    ...mikeButtons,
                    buttons[i]
                ]);
            }
        })();

        (async function() {
            let buttons = await getSocialMediaButtons("jen");
            for (let i = 0; i < buttons.length; i++) {
                setJenButtons(jenButtons => [
                    ...jenButtons,
                    buttons[i]
                ]);
            }
        })();
    }, []);

    return (
        <div className="section text-center">
            <Container>
                <h2 className="title">
                    About Us
                </h2>
                <Row>
                    <Col md="6">
                        <Card className="card-profile card-plain">
                            <div className="card-avatar">
                                <img alt="..." src={require("../assets/img/mike.png")}/>
                            </div>
                            <CardBody>
                                <div className="author">
                                    <CardTitle tag="h4">
                                        <b>Mike Carmona</b>
                                    </CardTitle>
                                    <h6 className="card-category">
                                        Owner
                                    </h6>
                                </div>
                                <p className="card-description text-center">
                                    With over 10 years of experience in the sound-for-picture business,
                                    Mike has recorded internationally for some of the biggest names in
                                    Hollywood.
                                </p>
                            </CardBody>
                            {mikeButtons.length > 0 ? <CardFooter className="text-center">
                                {mikeButtons}
                            </CardFooter>
                            : ""
                            }
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card className="card-profile card-plain">
                            <div className="card-avatar">
                                <img alt="..." src={require("../assets/img/jen.jpg")}/>
                            </div>
                            <CardBody>
                                <div className="author">
                                    <CardTitle tag="h4">
                                        <b>Jen Carmona</b>
                                    </CardTitle>
                                    <h6 className="card-category">
                                        Sound Designer
                                    </h6>
                                </div>
                                <p className="card-description text-center">
                                    After working for several production and post houses in Los Angeles, Jen is now the full-time sound designer and assistant mixer at Carmona Sound.
                                </p>
                            </CardBody>
                            {jenButtons.length > 0 ? <CardFooter className="text-center">
                                {jenButtons}
                            </CardFooter>
                            : ""
                            }
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionAboutUs;
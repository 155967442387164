import React from "react";
import {
    Container,
    Row,
    NavItem,
    NavLink,
    Nav
} from "reactstrap";
import SectionWorkVideoList from "./subcomponents/SectionWorkVideoList";

function SectionWork() {
    const [pills, setPills] = React.useState("production");
    const [category, setCategory] = React.useState(<SectionWorkVideoList category={pills}/>);

    React.useEffect(() => {
        if (pills === "production") {
            setCategory(<SectionWorkVideoList category="production"/>)
        } else {
            setCategory(<SectionWorkVideoList category="post-production"/>)
        }
    }, [pills]);

    return (
        <>
            <div className="section section-dark text-center" id="work_section">
                <Container>
                    <Row>
                        <div className="ml-auto mr-auto text-center col-md-8">
                            <h6 className="text-muted">Our work</h6>
                            <h2 className="title">Some of Our Awesome Work</h2>
                            <Nav className="nav-pills-danger justify-content-center nav nav-pills" pills role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={pills === "production" ? "active" : ""}
                                        onClick={() => setPills("production")}
                                        role="tablist"
                                        href="#!"
                                    >
                                        Production
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={pills === "post-production" ? "active" : ""}
                                        onClick={() => setPills("post-production")}
                                        role="tablist"
                                        href="#!"
                                    >
                                        Post-Production
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </Row>
                    {category}
                </Container>
            </div>{" "}
        </>
    );
}

export default SectionWork;

import React from "react";
import ScrollableAnchor from "react-scrollable-anchor"
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Container,
    Row,
    Col
} from "reactstrap";
import NavigationBar from "../components/Navbar.js";
import LandingPageHeader from "../components/LandingPageHeader.js";
import Footer from "../components/Footer.js";
import SectionAboutUs from "../components/SectionAboutUs";
import SectionWork from "../components/SectionWork";
import SectionContact from "../components/SectionContact";

function Layout() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");

        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });

    return (
        <>
            <NavigationBar />
            <LandingPageHeader />
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">
                                    What we do
                                </h2>
                                <h5 className="description">
                                    Carmona Sound is a full-service audio-for-picture solution. We offer rentals, location sound capture, and post as a single bid.
                                </h5>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col md="4">
                                <Card>
                                    <CardImg alt="Production Sound" src={require("../assets/img/prod_sound.jpg")} top>
                                    </CardImg>
                                    <CardBody>
                                        <CardTitle tag="h4">
                                            Production Sound
                                        </CardTitle>
                                        <CardText>
                                            From commercials to television series to major motion pictures; we are equipped to capture clean dialogue anywhere.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardImg alt="Post-Production Sound" src={require("../assets/img/post_sound.jpg")} top/>
                                    <CardBody>
                                        <CardTitle tag="h4">
                                            Post-Production Sound
                                        </CardTitle>
                                        <CardText>
                                            Our 5.1 mixing room is located in the heart of Las Vegas. From sound design to ADR, we take your soundtrack to the next level.
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <CardImg alt="Podcasting" src={require("../assets/img/podcasting.png")} top/>
                                    <CardBody>
                                        <CardTitle tag="h4">
                                            Podcasting
                                        </CardTitle>
                                        <CardText>
                                            We offer package deals on podcasting. Location recording and post for a single quote.
                                        </CardText>
                                    </CardBody>
                              </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <SectionWork/>
                <SectionAboutUs/>
                <ScrollableAnchor id={'contact'}>
                    <div>
                        <SectionContact/>
                    </div>
                </ScrollableAnchor>
            </div>
            <Footer />
        </>
    );
}

export default Layout;

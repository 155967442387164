import React from 'react';
import Layout from "./containers/Layout.js";

function App() {
    return (
    <div className="App">
        <Layout />
    </div>
    );
}

export default App;
